import React, { useState } from 'react'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import bootstrap from "../components/bootstrap.module.css"
import InfiniteScroll from 'react-infinite-scroll-component';
import SEO from "../components/seo"
import FormatDate from "../utils/format-date.js"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Events({ data }) {
	const totalCount = data.allMysqlEvent.nodes.length;
	const perPage = 10;

	const [currentList, updateList] = useState(() => {
		return data.allMysqlEvent.nodes.slice(0, perPage);
	})
	const [currentPage, updateCurrentPage] = useState(1)

	const fetchData = () => {
		updateCurrentPage(currentPage+1)
		updateList(data.allMysqlEvent.nodes.slice(0, currentPage*perPage))
	};
  
  	return (
	  
	  <Layout>

	  		<SEO title="Wydarzenia" canonical={'https://fintek.pl/wydarzenia/'} />  

  			<h1 className={bootstrap.pb2}>Wydarzenia</h1> 
        	<hr className={bootstrap.mb4} />  
			
			<InfiniteScroll
			dataLength={currentPage*perPage}
			next={fetchData}
			hasMore={currentPage*perPage<totalCount}
			loader={<div className={`spinner `+bootstrap.textCenter+` `+bootstrap.py3}><span></span><span></span><span></span><span></span></div>}
			>
				<div className={bootstrap.px2}>
					{currentList.map((node, i) => (
					<div key={i} className={`event-row `}>
						<AniLink fade to={`/wydarzenie/${node.post_name}/`} className={bootstrap.row+` `+bootstrap.py4+` `+bootstrap.m0}>
							<strong className={bootstrap.col6}>{node.post_title}</strong>
							<span className={bootstrap.col3}>{FormatDate(node.date, false)}</span>
							<strong className={bootstrap.col3+` `+bootstrap.textRight}>{node.city}</strong>
						</AniLink>
						<hr className={bootstrap.my0+` `+bootstrap.mx3} />
					</div>	
				))}  
				</div>	

			</InfiniteScroll>

     
    		
    </Layout>
  )
  
}

export const query = graphql`
	query EventsPage {
		allMysqlEvent(limit: 70, sort: {order: DESC, fields: date})  {
			nodes {
				post_name
				post_title
				date
				city
			}
		}	
	}
`
